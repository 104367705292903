import styled from 'styled-components';


export const Container = styled.div`
      @media (max-width: 480px){
            .ant-breadcrumb{
                  display: none !important;
            }
      }
`;
