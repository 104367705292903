import styled from 'styled-components';


export const Container = styled.div`
    >.me-3{
        transition: all 300ms ease;

    }
    .rotate-90{
        transform: rotate(90deg);
    }
`;